<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie dezaktywacji -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie dezaktywacji</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedUsers.length === 1">Czy na pewno chcesz dezaktywować {{ checkedUsers.length }} użytkownika?</div>
            <div v-if="checkedUsers.length > 1">Czy na pewno chcesz dezaktywować {{ checkedUsers.length }} użytkowników?</div>
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="blockUsers(); unselectUsers(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie aktywacji -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie aktywacji</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedUsers.length === 1">Czy na pewno chcesz aktywować {{ checkedUsers.length }} użytkownika?</div>
            <div v-if="checkedUsers.length > 1">Czy na pewno chcesz aktywować {{ checkedUsers.length }} użytkowników?</div>
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="activateUsers(); unselectUsers(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="checkedUsers.length === 1">Czy na pewno chcesz usunąć {{ checkedUsers.length }} użytkownika?</div>
            <div v-if="checkedUsers.length > 1">Czy na pewno chcesz usunąć {{ checkedUsers.length }} użytkowników?</div>
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteUsers(); unselectUsers(); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-flex justify-content-end mb-4">
      <!-- Filtr: Role -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle me-3"
        data-bs-toggle="dropdown"
      >
        {{ filter_permissions.length === 0 ? "Rola" : "Rola: " }}
        <span v-for="(item, index) in filter_permissions" :key="item" class="ms-1">{{ (item === "ROLE_ADMIN" ? "Administrator" : "Użytkownik") + (index !== filter_permissions.length - 1 ? ", " : "") }}</span>
      </material-button>
      <ul class="px-2 dropdown-menu shadow">
        <li>
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangePermission('ROLE_ADMIN')">
            Administrator
          </a>
        </li>
        <li>
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangePermission('ROLE_USER')">
            Użytkownik
          </a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <li>
          <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_permissions = []; getUsers(1)"
          >Wyczyść filtr</a>
        </li>
      </ul>

      <!-- Filtr: Status -->
      <material-button
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        class="dropdown-toggle me-3"
        data-bs-toggle="dropdown"
      >
        {{ filter_status.length === 0 ? "Status" : "Status: " }}
        <span v-for="(item, index) in filter_status" :key="item" class="ms-1">{{ item + (index !== filter_status.length - 1 ? ", " : "") }}</span>
      </material-button>
      <ul class="px-2 dropdown-menu shadow">
        <li>
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeStatus('Aktywny')">
            Aktywny
          </a>
        </li>
        <li>
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeStatus('Dezaktywowany')">
            Dezaktywowany
          </a>
        </li>
        <li>
          <a class="dropdown-item d-flex border-radius-md cursor-pointer" @click="onChangeStatus('Nieaktywny')">
            Nieaktywny
          </a>
        </li>
        <li>
          <hr class="horizontal bg-light my-2">
        </li>
        <li>
          <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_status = []; getUsers(1)"
          >Wyczyść filtr</a>
        </li>
      </ul>

      <!-- Przycisk: Nowy użytkownik -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Nowy użytkownik')"
      >
        + &nbsp; Nowy użytkownik
      </material-button>
    </div>

    <!-- Tabela: Użytkownicy -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Użytkownicy</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="filter_fullName"
                      v-model="filter_fullName"
                      placeholder="Wyszukaj..."
                      :items="usersAC"
                      :min-input-length="0"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectItem"
                    >
                    </SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                
                <!-- Tabela -->
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <td class="text-center" style="width: 5%">
                          <material-checkbox class="mx-auto" @change="selectUsers($event)" />
                        </td>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">
                          <a href="#" class="dataTable-sorter" @click="onOrder('fullName')">Imię i nazwisko</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">
                          <a href="#" class="dataTable-sorter" @click="onOrder('email')">Adres e-mail</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">
                          <a href="#" class="dataTable-sorter" @click="onOrder('roles')">Rola</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">
                          <a href="#" class="dataTable-sorter" @click="onOrder('status')">Status</a>
                        </th>

                        <th class="my-0 py-0 text-end" style="width: 10%">
                          <i
                            v-if="checkedUsers.length !== 0"
                            class="material-symbols-outlined h5 mt-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(1)"
                          >
                            update
                          </i>
                          <i
                            v-if="checkedUsers.length !== 0"
                            class="material-symbols-outlined h5 mt-2 ms-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(0)"
                          >
                            block
                          </i>
                          <i
                            v-if="checkedUsers.length !== 0"
                            class="material-symbols-outlined h5 mt-2 ms-2 cursor-pointer"
                            :class="isDarkMode ? 'text-white' : 'text-dark'"
                            @click="openModal(2)"
                          >
                            delete
                          </i>
                        </th>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <!-- Użytkownicy -->
                      <tr v-for="item in users" :key="item">
                        <!-- Tabela: Checkbox -->
                        <td class="text-center">
                          <material-checkbox class="mx-auto" :checked="item.mark" @change="selectUser($event, item)" />
                        </td>

                        <!-- Tabela: Imię i nazwisko -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd użytkownika', item.id)">
                          <div class="d-flex align-items-center">
                            <material-avatar
                              :img="$getAvatar(item.fullName, item.avatar)"
                              class="me-2 shadow"
                              size="xs"
                              circular
                              alt="user image"
                            />
                            {{ item.fullName }}
                          </div>
                        </td>

                        <!-- Tabela: Adres e-mail -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd użytkownika', item.id)">
                          {{ item.email }}
                        </td>

                        <!-- Tabela: Rola -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd użytkownika', item.id)">
                          {{ item.roles[0] === "ROLE_ADMIN" ? "Administrator" : "Użytkownik" }}
                        </td>

                        <!-- Tabela: Status -->
                        <td class="ps-0 text-xs cursor-pointer" @click="$redirectID('Podgląd użytkownika', item.id)">
                          <span class="me-1" :class="item.status === 'Aktywny' ? 'text-success' : item.status === 'Nieaktywny' ? 'text-dark' : 'text-danger'">&#x25cf;</span>
                          {{ item.status }}
                          <span v-if="item.lastLogin">({{ item.lastLogin }})</span>
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="$redirectID('Podgląd użytkownika', item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="$redirectID('Edycja użytkownika', item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li v-if="item.status === 'Dezaktywowany'" class="cursor-pointer" @click="unselectUsers(); item.mark = true; checkedUsers.push(item); openModal(1)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Aktywuj
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li v-else class="cursor-pointer" @click="unselectUsers(); item.mark = true; checkedUsers.push(item); openModal(0)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Dezaktywuj
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="unselectUsers(); item.mark = true; checkedUsers.push(item); openModal(2)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak użytkowników -->
                      <tr :class="users.length > 0 ? 'd-none' : null">
                        <td class="dataTables-empty text-sm" colspan="7">Nie znaleziono użytkowników</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="users.length !== 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event.target.value)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>

                      pozycji na stronę
                    </label>
                  </div>
                  
                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Users",
  components: {
    MaterialAvatar,
    MaterialCheckbox,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      users: [], // Tablica zawierająca dane użytkowników z API
      usersAC: [], // Tablica zawierająca wszystkie imiona i nazwiska użytkowników z API (AutoComplete)

      checkedUsers: [], // Tablica zawierająca wszystkich zaznaczonych użytkowników
      modals: [], // Tablica odpowiedzialna za status modali

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      column: "fullName", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      filter_fullName: "", // Filtr: Imię i nazwisko
      filter_permissions: [], // Filtr: Rola
      filter_status: [], // Filtr: Status
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_fullName(newSearch) {
      this.filter_fullName = newSearch
      this.getUsersDelay(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getUsers(this.page)
          this.getUsersAC()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje przefiltrowane dane użytkownika z API
    getUsers(page) {
      let status = ""
      this.filter_status.forEach(item => {
        status += `&status[]=${ item }`
      })

      UserDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&fullName=${ this.filter_fullName }${ status }&deleted=false`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)

        this.users = res.data["hydra:member"]
        
        if (this.filter_permissions.length === 1) {
          this.users = []
          res.data["hydra:member"].forEach(item => {
            if (item.roles[0] === this.filter_permissions[0]) {
              this.users.push(item)
            }
          })
        }
        else {
          if (this.column === "roles") {
            this.users = []
            if (this.columnOrder === "desc") {
              res.data["hydra:member"].forEach(item => {
                if (item.roles[0] === "ROLE_ADMIN") {
                  this.users.push(item)
                }
              })

              res.data["hydra:member"].forEach(item => {
                if (item.roles[0] === "ROLE_USER") {
                  this.users.push(item)
                }
              })
            }
            else {
              res.data["hydra:member"].forEach(item => {
                if (item.roles[0] === "ROLE_USER") {
                  this.users.push(item)
                }
              })

              res.data["hydra:member"].forEach(item => {
                if (item.roles[0] === "ROLE_ADMIN") {
                  this.users.push(item)
                }
              })
            }
          }
        }

        this.unselectUsers()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje przefiltrowane dane użytkownika z API z opóźnieniem
    getUsersDelay: _.debounce(function(page) {
      let status = ""
      this.filter_status.forEach(item => {
        status += `&status[]=${ item }`
      })

      UserDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&fullName=${ this.filter_fullName }${ status }&deleted=false`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)

        this.users = res.data["hydra:member"]

        if (this.filter_permissions.length === 1) {
          this.users = []
          res.data["hydra:member"].forEach(item => {
            if (item.roles[0] === this.filter_permissions[0]) {
              this.users.push(item)
            }
          })
        }
        else {
          if (this.column === "roles") {
            this.users = []
            if (this.columnOrder === "desc") {
              res.data["hydra:member"].forEach(item => {
                if (item.roles[0] === "ROLE_ADMIN") {
                  this.users.push(item)
                }
              })

              res.data["hydra:member"].forEach(item => {
                if (item.roles[0] === "ROLE_USER") {
                  this.users.push(item)
                }
              })
            }
            else {
              res.data["hydra:member"].forEach(item => {
                if (item.roles[0] === "ROLE_USER") {
                  this.users.push(item)
                }
              })

              res.data["hydra:member"].forEach(item => {
                if (item.roles[0] === "ROLE_ADMIN") {
                  this.users.push(item)
                }
              })
            }
          }
        }

        this.unselectUsers()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    }, 500),

    // Funkcja wczytuje wszystkich użytkowników z API
    getUsersAC() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.usersAC = []
        res.data["hydra:member"].forEach(item => {
          this.usersAC.push(item.fullName)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja dodająca zaznaczonego użytkownika do listy
    selectUser(e, item) {
      item.mark = e.target.checked
      if (e.target.checked) {
        this.checkedUsers.push(item)
      }
      else {
        this.checkedUsers.forEach((element, index) => {
          if (element.id === item.id) {
            this.checkedUsers.splice(index, 1)
          }
        })
      }
    },

    // Funkcja zaznaczająca wszystkich użytkowników
    selectUsers(e) {
      this.checkedUsers = []
      if(e.target.checked) {
        this.users.forEach(item => {
          item.mark = true
          this.checkedUsers.push(item)
        })
      }
      else {
        this.users.forEach(item => {
          item.mark = false
        })
      }
    },

    // Funkcja odznaczająca wszystkich użytkowników
    unselectUsers() {
      this.checkedUsers = []
      this.users.forEach(item => {
        item.mark = false
      })
    },

    // Funckja dezaktywująca wielu użytkowników
    blockUsers() {
      this.checkedUsers.forEach(item => {
        UserDataService.patch(item.id,
          {
            status: "Dezaktywowany",
          },
          {
            headers: { 'Content-Type': 'application/merge-patch+json' }
          }
        )
        .then(res => {
          console.log(res.data)
          this.getUsers(this.page)
          this.getUsersAC()
        })
        .catch(error => {
          console.log(error)

          if(JSON.stringify(error.response.data.code) == 401) {
            this.$logout()
          }
        })
      })
    },

    // Funckja aktywująca wielu użytkowników
    activateUsers() {
      this.checkedUsers.forEach(item => {
        UserDataService.patch(item.id,
          {
            status: "Nieaktywny",
          },
          {
            headers: { "Content-Type": "application/merge-patch+json" }
          }
        )
        .then(res => {
          console.log(res.data)
          this.getUsers(this.page)
          this.getUsersAC()
        })
        .catch(error => {
          console.log(error)

          if(JSON.stringify(error.response.data.code) == 401) {
            this.$logout()
          }
        })
      })
    },

    // Funckja usuwająca wiele użytkowników
    deleteUsers() {
      this.checkedUsers.forEach(item => {
        UserDataService.patch(item.id,
          {
            status: "Usunięty",
            deleted: true,
          },
          {
            headers: { "Content-Type": "application/merge-patch+json" }
          }
        )
        .then(res => {
          console.log(res.data)
          this.getUsers(this.page)
          this.getUsersAC()
        })
        .catch(error => {
          console.log(error)

          if(JSON.stringify(error.response.data.code) == 401) {
            this.$logout()
          }
        })
      })
    },

    // Funkcja przypisująca wybrane imię i nazwisko do zmiennej
    selectItem(item) {
      this.filter_fullName = item
      this.getUsers(1)
    },

    // Funkcja przypisująca do zmiennej wybraną rolę
    onChangePermission(event) {
      let exist = false

      this.filter_permissions.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_permissions.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_permissions.push(event)
      }

      this.getUsers(1)
    },

    // Funkcja przypisująca do zmiennej wybrany status
    onChangeStatus(event) {
      let exist = false

      this.filter_status.forEach((item, index) => {
        if (item === event) {
          exist = true
          this.filter_status.splice(index, 1)
        }
      })

      if (!exist) {
        this.filter_status.push(event)
      }

      this.getUsers(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(event) {
      this.filter_recordsPerPage = event
      this.getUsers(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getUsers(page)
      }
      else {
        this.getUsers(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getUsers(page)
      }
      else {
        this.getUsers(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getUsers(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getUsers(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>