<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz usunąć zdjęcie?
           
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteImage(deletedImage)"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <!-- Tabela: Produkty -->
    <div class="row">
      <div class="mb-4" :class="product ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Produkty</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <SimpleTypeahead
                      ref="searchRef"
                      v-model="filter_name"
                      placeholder="Wyszukaj..."
                      :items="productsAC"
                      :min-input-length="1"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectItem"
                    ></SimpleTypeahead>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('id')">ID</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 50%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('price')">Cena</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('category')">Kategoria</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in products"
                        :key="item"
                        :class="product && product.id === item.id ? 'bg-light' : ''"
                      >
                        <!-- ID -->
                        <td class="cursor-pointer" @click="product && product.id === item.id ? product = null : getProduct(item.id)">
                          <p class="m-0 text-xs">#{{ item.id }}</p>
                        </td>

                        <!-- Nazwa -->
                        <td class="ps-0 cursor-pointer" @click="product && product.id === item.id ? product = null : getProduct(item.id)">
                          <p class="m-0 text-xs">{{ item.name ? item.name : "Brak" }}</p>
                        </td>

                        <!-- Cena -->
                        <td class="ps-0 cursor-pointer" @click="product && product.id === item.id ? product = null : getProduct(item.id)">
                          <p class="m-0 text-xs">{{ item.price }} PLN</p>
                        </td>

                        <!-- Kategoria -->
                        <td class="ps-0 cursor-pointer" @click="product && product.id === item.id ? product = null : getProduct(item.id)">
                          <p class="m-0 text-xs text-capitalize">{{ item.category }}</p>
                        </td>

                        <!-- Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="getProduct(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak produktów -->
                      <tr :class="products.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="5">Nie znaleziono produktów</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="products.length > 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Produkt -->
      <div v-if="product" class="col-md-4">
        <div class="card position-sticky top-1 overflow-auto">
          <div class="card-header pb-1">
            <h5>{{ product.name }}</h5>
          </div>
          <div class="card-body pt-1 pb-0">
            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Kod produktu: <span class="font-weight-normal ms-2">{{ product.externalId ? product.externalId : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  GID: <span class="font-weight-normal ms-2">{{ product.gid ? product.gid : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Cena: <span class="font-weight-normal ms-2">{{ product.price ? product.price : "0.00" }} PLN</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Kategoria: <span class="font-weight-normal text-capitalize ms-2">{{ product.category ? product.category : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Status: <span class="font-weight-normal text-capitalize ms-2">{{ product.status ? product.status : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Data ostatniej aktualizacji: <span class="font-weight-normal text-capitalize ms-2">{{ product.lastUpdate ? moment(product.lastUpdate).format("DD/MM/YYYY HH:mm:ss") : "Brak" }}</span>
                </label>
              </div>
            </div>

            <h5 class="mt-3 mb-1">Cechy</h5>

            <div v-for="productFeatureValue in product.productFeatureValues" :key="productFeatureValue" class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  {{ productFeatureValue.productFeature.name }}: <span class="font-weight-normal ms-2">{{ productFeatureValue.value }}</span>
                </label>
              </div>
            </div>
            
            <h5 class="my-3 d-flex align-items-center">
              Zdjęcia
              <label class="p-0 m-0">
                <i class="material-symbols-outlined mt-1 ms-1 text-md cursor-pointer" :class="`text-${ color }`">
                  add_circle
                  <input type="file" class="d-none" accept=".jpg, .jpeg, .png" @change="postImage($event.target.files[0])">
                </i>
              </label>
            </h5>

            <div class="row">
              <div v-for="image in product.images" :key="image" class="col-md-4 mb-2 position-relative">
                <img :src="'data:image/jpeg;base64,' + image.fileBlob" class="w-100 cursor-pointer" @click="$store.state.image = image.id; $redirect('Zdjęcia')">

                <a
                  class="position-absolute rounded-circle text-center text-light cursor-pointer"
                  :class="`bg-gradient-${ color }`"
                  style="width: 22px; height: 22px; top: -11px; right: -3px"
                  @click="deletedImage = image.id; openModal(0)"
                >
                  <i class="material-symbols-outlined text-sm" style="margin-top: 1px; margin-left: 1px;">close</i>
                </a>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-12 d-flex justify-content-end">
                <material-button
                  class="me-3"
                  :color="color"
                  variant="gradient"
                  @click="exportProduct(product.id)"
                >
                  Aktualizuj w B2B
                </material-button>

                <material-button
                  color="light"
                  variant="gradient"
                  @click="product = null"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import ProductDataService from "@/services/ProductDataService"
import ImageDataService from "@/services/ImageDataService"
import ImportDataService from "@/services/ImportDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"

export default {
  name: "Products",
  components: {
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      product: null, // Obiekt wybranego produktu

      deletedImage: null, // Obiekt usuwanego zdjęcia

      products: [], // Tablica zawierająca dane produktów z API
      productsAC: [], // Tablica zawierająca nazwy produktów z API

      modals: [], // Tablica obecnych modali

      column: "id", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      filter_name: "", // Filtr: Nazwa
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      this.getProducts(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted) {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getProducts(this.page)
          this.getProductsAC()

          if (this.$store.state.product) {
            this.getProduct(this.$store.state.product)
            this.$store.state.product = null
          }
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wybrany produkt z API
    getProduct(id) {
      ProductDataService.get(id)
      .then(res => {
        this.product = res.data
        this.getImages(this.product.id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca zdjęcia do wybranego produktu
    getImages(id) {
      ImageDataService.getAll(`?product.id=${ id }`)
      .then(res => {
        this.product.images = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowe zdjęcie do produktu
    postImage(event) {
      const formData = new FormData()
      formData.append("file", event)
      formData.append("product", this.product.id)

      ImageDataService.post(formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getProduct(this.product.id)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca produkt w B2B
    exportProduct(id) {
      ImportDataService.export(`1?exec=1&internalId=${ id }`)
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca zdjęcie
    deleteImage(id) {
      ImageDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.deletedImage = null
        this.getProduct(this.product.id)
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca produkty z API
    getProducts(page) {
      let search = this.filter_name ? `&search=${ this.filter_name }` : ""

      ProductDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }${ search }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.products = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca nazwy produktów z API
    getProductsAC() {
      ProductDataService.getAll("?itemsPerPage=1000000000")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.productsAC.push(item.externalId)
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_name = item
      this.getProducts(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(e) {
      this.filter_recordsPerPage = e.target.value
      this.getProducts(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getProducts(page)
      }
      else {
        this.getProducts(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getProducts(page)
      }
      else {
        this.getProducts(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getProducts(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getProducts(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}
.simple-typeahead {
  color: #000;
}
</style>