<template>
  <div v-if="me" class="container-fluid">
    <div class="row">
      <div v-if="!me.settings.displayHistory" class="text-sm text-center">
        Kokpit jest pusty!
        Moduły możesz dodać w swoich <router-link to="/home/profile/settings" :class="`text-${ color }`">ustawieniach</router-link>!
      </div>

      <div v-if="me.settings.displayHistory" class="col-md-6">
        <!-- Historia -->
        <div v-if="me.settings.displayHistory" class="card mb-4">
          <div class="card-header pb-1">
            <h6 class="font-weight-bold">Historia</h6>
          </div>
          <div class="card-body pt-1">
            <div v-if="history.length === 0" class="text-sm">Brak historii</div>
            <label v-for="(item, index) in history" :key="index" class="w-100">
              <div class="d-flex">
                <material-avatar
                  :img="$getAvatar(item.owner.fullName, item.owner.avatar)"
                  class="me-3 shadow"
                  circular
                  alt="avatar image"
                />
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ item.owner.fullName }} <span class="font-weight-normal">{{ item.description }}</span></h6>
                  <p class="mb-0 text-xs text-secondary opacity-7">{{ item.createdAt }}</p>
                </div>
              </div>
              <hr v-if="index !== history.length - 1" class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import UserDataService from "@/services/UserDataService"
import UserHistoryDataService from "@/services/UserHistoryDataService"
import SettingsDataService from "@/services/SettingsDataService"
import { mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Dashboard",
  components: {
    MaterialAvatar,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika
      history: [], // Tablica zawierająca dane historii z API
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"]),

    sorted() {
      return _.orderBy(this.mailbox, "date", "desc")
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(uid) {
      UserDataService.get(uid)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }

        this.me = res.data
        
        if (!this.me.settings) {
          this.postSettings(res.data.id)
        }

        if (res.data.settings.displayHistory) {
          this.getHistories()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje dane historii z API
    getHistories() {
      UserHistoryDataService.getAll("?order[createdAt]=desc")
      .then(res => {
        this.history = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca ustawienia, jeśli brakuje
    postSettings(id) {
      SettingsDataService.post(
        {
          owner: `/users/${ id }`,
          displayHistory: true,
          displayMailbox: true,
          displayCustomers: true,
          displayOffers: true,
          displayCalendar: true,
          displayTasks: true,
          displayNotifications: true,
          messagesCount: 5,
          sendNotifications: true,
          playSound: true,
          pushNotifications: true,
          displayMail: true,
          darkMode: false,
          sidebarColor: "primary",
          sidebarType: "bg-gradient-dark",
        }
      )
      .then(res => {
        console.log(res.data)
        this.getMe()
      })
      .catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}
</style>