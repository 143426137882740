<template>
  <div v-if="me && user" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Użytkownicy')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#profile" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">person</i>
                <span class="text-sm">Profil</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-fle align-items-centerx" data-scroll href="#user-data" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Dane użytkownika</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#history" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">desktop_windows</i>
                <span class="text-sm">Historia logowania</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Profil -->
        <div id="profile" class="card mb-4">
          <div class="card-body pb-3">
            <div class="row">
              <div class="col-sm-auto col-4">
                <material-avatar
                  :img="$getAvatar(user.fullName, user.avatar)"
                  alt="bruce"
                  size="xl"
                  shadow="sm"
                  circular
                />
              </div>

              <div class="col-md-auto col-8 my-auto">
                <div class="h-100">
                  <h5 class="mb-1 font-weight-bolder">{{ user.fullName }}</h5>
                  <p class="mb-0 text-sm">{{ user.roles[0] === "ROLE_ADMIN" ? "Administrator" : "Użytkownik" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Dane użytkownika -->
        <div id="user-data" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Dane użytkownika</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Imię: <span class="font-weight-normal ms-2">{{ user.fullName.substring(0, user.fullName.indexOf(" ")) }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Nazwisko: <span class="font-weight-normal ms-2">{{ user.fullName.substring(user.fullName.indexOf(" ") + 1) }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Adres e-mail: <span class="font-weight-normal ms-2">{{ user.email }}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Numer telefonu: <span class="font-weight-normal ms-2">{{ user.phoneNumber }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Historia logowania -->
        <div id="history" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Historia logowania</h5>
          </div>
          <div class="card-body pt-1">
            <div v-if="sessions.length === 0" class="d-flex align-items-center text-sm">Brak ostatnich sesji</div>
            <div v-for="(item, index) in sessions" v-else :key="item">
              <div class="d-flex align-items-center">
                <i class="material-symbols-outlined me-2">computer</i>
                <p class="my-auto text-sm">{{ item.createdAt }}</p>
                <material-badge v-if="index === 0" color="success" size="sm" class="ms-auto">Aktywne</material-badge>
              </div>
              <hr v-if="index !== sessions.length - 1" class="horizontal dark" />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="$redirectID('Edycja użytkownika', user.id)"
          >
            Edytuj
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import SessionDataService from "@/services/SessionDataService"
import { mapState } from "vuex"

export default {
  name: "OverviewUser",
  components: {
    MaterialAvatar,
    MaterialBadge,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika
      
      user: null, // Obiekt edytowanego użytkownika
      sessions: [], // Lista 3 ostatnich sesji użytkownika
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.verifyUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    verifyUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getUser()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane edytowanego użytkownika
    getUser() {
      UserDataService.get(this.$route.params.id)
      .then(res => {
        this.user = res.data
        this.getSessions()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o sesjach edytowanego użytkownika
    getSessions() {
      SessionDataService.getAll(`?itemsPerPage=3&order[createdAt]=desc&owner.id=${ this.user.id }`)
      .then(res => {
        this.sessions = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}
</style>