<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-4'"
  >
    <div class="px-4 py-1 container-fluid">
      <!-- Aktualna lokalizacja -->
      <breadcrumbs :current-page="currentRouteName" :color="myColor" />

      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <ul class="navbar-nav justify-content-end ms-md-auto">
          <!-- Profil -->
          <li
            v-if="me"
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-0'"
          >
            <a
              class="px-0 nav-link font-weight-bold lh-1 cursor-pointer"
              :class="[myColor]"
              data-bs-toggle="dropdown"
            >
              <material-avatar
                :img="$getAvatar(me.fullName, me.avatar)"
                size="xs"
                shadow="sm"
                circular
                alt="user image"
              />
            </a>
            <ul
              class="p-2 dropdown-menu dropdown-menu-end me-sm-n4 shadow"
              style="cursor: default"
              @click="$event.stopPropagation()"
            >
              <li>
                <div class="pt-2 d-flex">
                  <div class="ms-2 d-flex flex-column justify-content-center">
                    <h6 v-if="me" class="text-sm font-weight-bold" style="cursor: text">
                      {{ me.fullName }}
                    </h6>
                  </div>
                </div>
              </li>
              <hr class="m-1">
              <li>
                <a class="dropdown-item border-radius-md cursor-pointer" @click="$redirect('Zarządzanie kontem')">
                  <div class="pt-2 d-flex">
                    <div class="my-auto">
                      <i class="material-symbols-outlined cursor-pointer">
                        account_circle
                      </i>
                    </div>
                    <div class="ms-3 d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal">
                        Zarządzanie kontem
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md cursor-pointer" @click="$redirect('Personalizacja')">
                  <div class="pt-2 d-flex">
                    <div class="my-auto">
                      <i class="material-symbols-outlined cursor-pointer">
                        settings
                      </i>
                    </div>
                    <div class="ms-3 d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal">
                        Personalizacja
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <hr class="m-1">
              <li>
                <a class="dropdown-item border-radius-md cursor-pointer" @click="logout()">
                  <div class="pt-2 d-flex">
                    <div class="my-auto">
                      <i class="material-symbols-outlined cursor-pointer">
                        logout
                      </i>
                    </div>
                    <div class="ms-3 d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal">
                        Wyloguj
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>

          <!-- Menu -->
          <li class="ps-2 ms-1 nav-item d-xl-none d-flex align-items-center">
            <a
              class="p-0 nav-link lh-1 cursor-pointer"
              :class="myColor"
              @click="toggleSidebar"
            >
              <i class="material-icons" :class="isRTL ? 'ms-sm-2' : 'me-sm-0'" :style="isDarkMode ? 'color: white' : 'color: #344767'">
                menu
              </i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Breadcrumbs from "@/examples/Breadcrumbs.vue"
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import LoginDataService from "@/services/LoginDataService"
import UserDataService from "@/services/UserDataService"
import { mapMutations, mapState } from "vuex"

export default {
  name: "Navbar",
  components: {
    MaterialAvatar,
    Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function,
      default: () => {}
    },
    myColor: {
      type: String,
      default: "text-body"
    }
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika
    }
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute", "isDarkMode", "color"]),

    currentRouteName() {
      return this.$route.name
    }
  },
  created() {
    if(localStorage.getItem("token")) {
      this.getMe()
    }

    this.minNav
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize()
    },

    // Funkcja pobierająca dane o obecnie zalogowanym użytkowniku
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o obecnie zalogowanym użytkowniku
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        this.me = res.data
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wylogowująca użytkownika
    logout() {
      LoginDataService.logout(
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        console.log(res.data)
        localStorage.removeItem("token")
        this.$router.push({ name: "Login" })  
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>
.line {
  width: 2px;
  height: 35px;
  background-color: #dee0e6;
}
</style>