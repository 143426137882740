import { createRouter, createWebHistory } from "vue-router"
import SignIn from "@/views/SignIn.vue"
import ResetPassword from "@/views/ResetPassword.vue"
import Dashboard from "@/views/home/Dashboard.vue"
import Logs from "@/views/home/database/Logs.vue"
import Products from "@/views/home/database/Products.vue"
import Images from "@/views/home/database/Images.vue"
import Customers from "@/views/home/database/Customers.vue"
import Prices from "@/views/home/database/Prices.vue"
import Edi from "@/views/home/operations/Edi.vue"
import ImportTypes from "@/views/home/operations/ImportTypes.vue"
import ExportTypes from "@/views/home/operations/ExportTypes.vue"
import Orders from "@/views/home/sale/Orders.vue"
import Overview from "@/views/home/profile/Overview.vue"
import Settings from "@/views/home/profile/Settings.vue"
import Users from "@/views/home/admin/users/Users.vue"
import NewUser from "@/views/home/admin/users/NewUser.vue"
import EditUser from "@/views/home/admin/users/EditUser.vue"
import OverviewUser from "@/views/home/admin/users/OverviewUser.vue"

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in"
  },
  {
    path: "/sign-in",
    name: "Login",
    component: SignIn
  },
  {
    path: "/reset-password",
    name: "Resetowanie hasła",
    component: ResetPassword
  },
  {
    path: "/home/dashboard",
    name: "Kokpit",
    component: Dashboard
  },
  {
    path: "/home/logs",
    name: "Logi",
    component: Logs
  },
  {
    path: "/home/products",
    name: "Produkty",
    component: Products
  },
  {
    path: "/home/images",
    name: "Zdjęcia",
    component: Images
  },
  {
    path: "/home/customers",
    name: "Kontrahenci",
    component: Customers
  },
  {
    path: "/home/prices",
    name: "Ceny",
    component: Prices
  },
  {
    path: "/home/edi",
    name: "Edi",
    component: Edi
  },
  {
    path: "/home/import-types",
    name: "Typy importu",
    component: ImportTypes
  },
  {
    path: "/home/export-types",
    name: "Typy exportu",
    component: ExportTypes
  },
  {
    path: "/home/orders",
    name: "Zamówienia",
    component: Orders
  },
  {
    path: "/home/profile/overview",
    name: "Zarządzanie kontem",
    component: Overview
  },
  {
    path: "/home/profile/settings",
    name: "Personalizacja",
    component: Settings
  },
  {
    path: "/home/admin/users",
    name: "Użytkownicy",
    component: Users
  },
  {
    path: "/home/admin/new-user",
    name: "Nowy użytkownik",
    component: NewUser
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/admin/edit-user/:id",
    name: "Edycja użytkownika",
    component: EditUser
  },
  {
    // Strona wczytywana na podstawie podanego ID
    path: "/home/admin/overview-user/:id",
    name: "Podgląd użytkownika",
    component: OverviewUser
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active"
})

export default router